<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-row class="justify-content-center pa-5">
      <v-col cols="12">
        <v-card-title
          style="background-color: transparent !important"
          class="ps-0 pe-0"
        >
          <h3>افزودن بیمار جدید</h3>
        </v-card-title>
        <v-card class="cards">
          <v-card-text>
            <div class="pa-5">
              <!--<v-row class="mt-2">
                <i
                  class="
                    fa fa-exclamation-triangle fa-2x
                    text-warning
                    me-3
                    mt-1
                  "
                  aria-hidden="true"
                ></i>
                <p class=" mt-3 me-2">
                  لطفا توجه فرمایید تکمیل موارد ستاره‌دار ضروری است!
                </p>
              </v-row>
              <hr class="mt-1" />-->

              <v-row>
                <v-col sm="6" cols="12" class="pb-0">
                  <v-text-field
                    label="*نام "
                    outlined
                    dense
                    v-model="userData.firstName"
                  ></v-text-field>
                  <v-text-field
                    label="نام پدر"
                    outlined
                    dense
                    v-model="userData.fatherName"
                  ></v-text-field>
                  <v-text-field
                    label="*کد ملی"
                    outlined
                    dense
                    v-model="userData.natCode"
                    type="number"
                    :rules="[isID]"
                  ></v-text-field>
                  <v-text-field
                    label="استان"
                    outlined
                    dense
                    v-model="userData.state"
                  ></v-text-field>
                  <v-select
                    label="*نوع بیمه"
                    outlined
                    dense
                    v-model="userData.insurType"
                    :items="insurTypes"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                  <div>
                    <span id="bday">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="calendar_today"
                        v-model="userData.birthday"
                        label=" تاریخ تولد "
                        :editable="true"
                        class="date-input"
                      >
                      </v-text-field>
                    </span>

                    <date-picker
                      v-model="userData.birthday"
                      element="bday"
                      color="#00a7b7"
                    />
                  </div>

                  <v-text-field
                    label="*شماره تلفن همراه"
                    outlined
                    dense
                    v-model="userData.mobile"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col sm="6" cols="12" class="pb-0">
                  <v-text-field
                    label="*نام خانوادگی "
                    outlined
                    dense
                    v-model="userData.lastName"
                  ></v-text-field>
                  <v-select
                    label="*جنسیت"
                    outlined
                    dense
                    v-model="userData.gender"
                    :items="genders"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                  <v-select
                    label="گروه خونی"
                    outlined
                    dense
                    v-model="userData.bloodType"
                    :items="bloodTypes"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                  <v-text-field
                    label="شهر"
                    outlined
                    dense
                    v-model="userData.city"
                  ></v-text-field>
                  <v-text-field
                    label="شماره بیمه"
                    outlined
                    dense
                    type="number"
                    v-model="userData.insurNo"
                  ></v-text-field>
                  <v-text-field
                    label="ایمیل"
                    outlined
                    dense
                    v-model="userData.email"
                    :rules="[
                      validateEmail(userData.email)
                        ? true
                        : 'ایمیل وارد شده صحیح نمی‌باشد',
                    ]"
                  ></v-text-field>
                  <v-text-field
                    label="شماره تلفن ثابت"
                    outlined
                    dense
                    v-model="userData.tel"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-row class="px-3">
                  <v-col cols="12" sm="12" md="6" lg="6" class="pt-0">
                    <v-checkbox
                      v-model="userData.insuranceLastSheet"
                      class="lastSheet-checkBox"
                      color="primary"
                      label="تا آخرین صفحه دفترچه"
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" class="pt-0">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="userData.insuranceExpirationDate"
                      label="تاریخ انقضا دفترچه"
                      :editable="true"
                      class="date-input mt-2"
                      :disabled="userData.insuranceLastSheet"
                    >
                      <template #append>
                        <span id="insuranceExpirationDate">
                          <v-icon class="calender-icon"
                            >mdi-calendar-blank</v-icon
                          >
                        </span>
                      </template>
                    </v-text-field>
                    <date-picker
                      v-model="userData.insuranceExpirationDate"
                      element="insuranceExpirationDate"
                      color="#00a7b7"
                      :disabled="userData.insuranceLastSheet"
                    />
                  </v-col>
                </v-row>

                <v-col cols="12" class="pt-0">
                  <v-textarea
                    v-model="userData.address"
                    label="آدرس دقیق"
                    outlined
                    dense
                    rows="1"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-btn
                class="primary-btn submit-btn"
                @click="submit()"
                :disabled="
                  !(
                    userData.firstName &&
                    userData.lastName &&
                    userData.gender &&
                    userData.insurType &&
                    userData.mobile &&
                    userData.natCode &&
                    checkNatCode(userData.natCode)
                  )
                "
                >ثبت
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { mapGetters } from "vuex";

function checkCodeMeli(code) {
  var L = code.length;

  if (L < 8 || parseInt(code, 10) == 0) return false;
  code = ("0000" + code).substr(L + 4 - 10);
  if (parseInt(code.substr(3, 6), 10) == 0) return false;
  var c = parseInt(code.substr(9, 1), 10);
  var s = 0;
  for (var i = 0; i < 9; i++) s += parseInt(code.substr(i, 1), 10) * (10 - i);
  s = s % 11;
  return (s < 2 && c == s) || (s >= 2 && c == 11 - s);
}

export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      userData: {},
      role: "",
      states: [
        { value: "فعال", text: "فعال" },
        { value: "غیرفعال", text: "غیرفعال" },
      ],
      genders: [
        { value: "زن", text: "زن" },
        { value: "مرد", text: "مرد" },
      ],
      bloodTypes: [
        { value: "A+", text: "+A" },
        { value: "A-", text: "-A" },
        { value: "B-", text: "-B" },
        { value: "B+", text: "+B" },
        { value: "AB+", text: "+AB" },
        { value: "AB-", text: "-AB" },
        { value: "O+", text: "+O" },
        { value: "O-", text: "-O" },
      ],
    };
  },
  computed: {
    ...mapGetters(["insurTypes"]),
  },
  methods: {
    validateEmail(email) {
      if (email) {
        var re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      } else return true;
    },
    isID(id) {
      // NOTE (m-isID) to check the validity of the national code
      return id
        ? checkCodeMeli(id)
          ? true
          : "کدملی وارد شده صحیح نمی‌باشد"
        : true;
    },

    checkNatCode(id) {
      return id ? (checkCodeMeli(id) ? true : false) : false;
    },
    submit() {
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/new",
          {
            fatherName: this.userData.fatherName,
            birthday: this.userData.birthday,
            gender: this.userData.gender,
            firstName: this.userData.firstName,
            lastName: this.userData.lastName,
            mobile: this.userData.mobile,
            insuranceId: this.userData.insurType,
            insurNo: this.userData.insurNo,
            city: this.userData.city,
            state: this.userData.state,
            address: this.userData.address,
            tel: this.userData.tel,
            email: this.userData.email,
            bloodType: this.userData.bloodType,
            natCode: this.userData.natCode,
            insuranceExpirationDate:this.userData.insuranceLastSheet ? '' : this.userData.insuranceExpirationDate,
            insuranceLastSheet: this.userData.insuranceLastSheet,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.toast("بیمار با موفقیت ثبت گردید", "success");
            let role = this.role == "reception" ? "reception" : "admin";
            this.$router.push("/" + role + "/Patients");
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
  },
};
</script>
